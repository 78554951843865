import { Component, Input } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Card } from '../../model/landing-page/card.model';
import { MonitoringService } from '../../services/monitoring.service';
import {
  removeAccentsAndPutInLowercase,
  replaceSpaceToHifen
} from '../../utils/format';

@Component({
  selector: 'mob-icon-cards',
  templateUrl: './icon-cards.component.html',
  styleUrls: ['./icon-cards.component.scss'],
})
export class IconCardsComponent {
  @Input() cards: Array<Card> = [];
  @Input() circle: boolean = false;
  @Input() withBorder: boolean = false;
  @Input() withSlider: boolean = false;
  @Input() alignCenter: boolean = false;
  @Input() size: 'small' | 'medium' | 'large' = 'large';

  constructor(private monitoringService: MonitoringService) {}

  slideVisualized = [];

  swiperConfig: SwiperOptions = {
    pagination: { clickable: true, type: 'bullets' },
    watchSlidesProgress: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      450: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      740: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    },
  };

  accountCarousel(event) {
    let hasId = this.slideVisualized.find(
      (resp) => resp === this.cards[event.activeIndex].title
    );

    if (!hasId) {
      const productTitle = this.cards[event.activeIndex].title;
      this.slideVisualized.push(productTitle);
      const labelTagging = replaceSpaceToHifen(
        removeAccentsAndPutInLowercase(`card:conta-${productTitle}`)
      );
      this.monitoringService.click(labelTagging);
    }
  }
}
