import { TaggingConfig } from '@afe/tagging';
import { TaggingDynatraceConnector } from '@afe/tagging/dynatrace-connector';
import { TaggingGtmConnector } from '@afe/tagging/gtm-connector';

export const taggingConfig: TaggingConfig = {
    connectors: [
        TaggingGtmConnector,
        TaggingDynatraceConnector,
    ],
};
