import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  if (environment.flag_flickering) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `!function(e,a,n,t){var i=e.head;if(i){
    if (a) return;
    var o=e.createElement("style");
    o.id="alloy-prehiding",o.innerText=n,i.appendChild(o),setTimeout(function(){o.parentNode&&o.parentNode.removeChild(o)},t)}}
    (document, document.location.href.indexOf("adobe_authoring_enabled") !== -1, ".personalization-container { opacity: 0 !important }", 3000);`;
    document.getElementsByTagName('head')[0].appendChild(script);
 }

  enableProdMode();
}

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
