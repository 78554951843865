import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import * as bowser from "bowser";
import { SwiperOptions } from "swiper";
import { Card } from "../../model/landing-page/card.model";
import { MonitoringService } from './../../../shared/services/monitoring.service';

@Component({
  selector: 'mob-centered-image-with-cards',
  templateUrl: './centered-image-with-cards.component.html',
  styleUrls: ['./centered-image-with-cards.component.scss'],
})
export class ImageCentereWithCardsComponent {
  public allCards: Array<Card> = [];
  public leftCards = [];
  public rigthCards = [];
  public isMobile = bowser.mobile;
  public innerWidth: any;

  @Input() hasMoreDetails: boolean = false;
  @Input() isIconBlack: boolean = false;
  @Input() gapValue = 0;

  @Output() seeMoreClicked = new EventEmitter();

  @Input() set cards(value: Array<Card>) {
    this.allCards = value;

    const totalOfCards: number = value.length;

    const leftTotals = Number.parseInt((totalOfCards / 2).toString());
    const rigthTotals = totalOfCards - leftTotals;

    this.leftCards = value.slice(0, leftTotals);
    this.rigthCards = value.slice(leftTotals, value.length);
  }

  @Input() imageUrl: string;

  swiperConfig: SwiperOptions = {
    pagination: { clickable: true, type: 'bullets' },
    watchSlidesProgress: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      500: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
    },
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(private monitoringService: MonitoringService) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  public onSeeMoreClick(product) {
    this.monitoringService.click('link:saiba-mais:combo-santander');
    this.seeMoreClicked.emit(product);
  }
}
