import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { CmsService } from "../services/cms/cms.service";
import { StorageModule } from '../utils/storage.module';

@Injectable({ providedIn: 'root' })
export class StepPageResolver implements Resolve<any> {
    constructor(
        @Inject(CmsService) protected cmsService: CmsService,
        @Inject(StorageModule) protected storage: StorageModule,
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const pageId = this.getPageId(route);
        return this.cmsService.getPageDetail(pageId);
    }

    /**
   * Retrieves the page id
   * Can be overwritten
   */
    getPageId(routeSnapshot: ActivatedRouteSnapshot): string {
        return routeSnapshot.data && routeSnapshot.data.route && routeSnapshot.data.route.cmsId ? routeSnapshot.data.route.cmsId: routeSnapshot.data.cmsId;
    }
}
