import { environment } from '../environments/environment';

export const urlConfig = Object.freeze({
    url_hub_cpf: environment.cpfEndpoint+'/'+environment.cpfResourcePath,
    url_hub_cms: environment.cmsEndpoint+'/'+environment.cmsResourcePath,
    app_key: environment.appKey,
    cms_x_staging:environment.cmsXStaging,
    url_mob: environment.mob,
    url_opf: environment.opf,
    url_fopa: environment.fopa,
    encryptionPEM: environment.encryptionPEM,
    keyAdobeTarget: environment.key_adobe_target
});
