import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DssAccordionComponent } from "@dss/components";
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from "swiper/angular";
import { removeAccentsAndPutInLowercase, replaceSpaceToHifen } from '../../../shared/utils/format';
import { GalleryItem } from '../../model/landing-page/gallery.model';
import { ITaggingImpression } from '../../model/tagging-impression.interface';
import { MonitoringService } from './../../../shared/services/monitoring.service';

@Component({
  selector: 'mob-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {

  @Input() items: Array<GalleryItem> = []
  @Input() public activeItem = 5;
  @Input() isMock: boolean;
  @Output() seeMoreClicked = new EventEmitter();
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('accordionGallery', { static: false }) accordionGallery?: DssAccordionComponent;
  carsAlreadyViewed: ITaggingImpression[] = [];
  countInteration: number = 0;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private monitoringService: MonitoringService
  ) {}

  currentItem: number = 0;

  public config: SwiperOptions = {
    slidesPerView: "auto",
    watchSlidesProgress: true,
    pagination: { clickable: true, type: "bullets" }
  };

  public onSeeMoreClick(product: GalleryItem) {
    this.seeMoreClicked.emit(product);
  }

  public openAccordionCards(product: GalleryItem) {
    this.countInteration++;
    if(this.countInteration > 1){
      this.tagging(`dropdown:${product.title}`, 'selected')
      setTimeout(() => {
        const item = this.items.indexOf(product)
        this.swiper?.setIndex(item)
      }, 300)
    }
  }

  slideChangeCards(event) {
    this.tagging(`card:${this.items[event.activeIndex].title}`, 'show');
    this.changeCard(event);
  }

  private tagging(label: string, type: string) {
    const labelTagging = replaceSpaceToHifen(removeAccentsAndPutInLowercase(label))
    this.monitoringService[type](labelTagging)
  }

  private changeCard(event) {
    this.currentItem = event?.activeIndex;
    if (this.accordionGallery) {
      const item = this.accordionGallery?.accordionItems.toArray()[this.currentItem]

      if (!item.isExpanded) {
        item?.toggle();
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  impressionTagging(value: string) {
    const impressionGTM = {
      title: replaceSpaceToHifen(removeAccentsAndPutInLowercase(value))
    } as ITaggingImpression;
    const indexOf = this.carsAlreadyViewed.findIndex(
      item => item.title === impressionGTM.title
    );
    if (indexOf == -1) {
      this.carsAlreadyViewed.push(impressionGTM);
      this.monitoringService.productImpression(this.carsAlreadyViewed);
    }
  }

}
