<div class="icon-cards full-container" >
   <ng-container *ngIf="withSlider">
      <swiper [config]="swiperConfig"
      (swiper)="accountCarousel($event)" (slideChange)="accountCarousel($event)">
         <ng-container>
            <ng-template swiperSlide *ngFor="let card of cards">
               <div dssCard class="icon-cards__item"
               [class.borderLeft]="withBorder"
               [class.align-center]="alignCenter"
               >
                  <div [ngClass]="{'circle': circle}">
                     <span dssIcon [name]='card?.icon' [size]='size'></span>
                  </div>
                  <p dssTypography type='caption' [innerHtml]="card?.title"></p>
              </div>
            </ng-template>
         </ng-container>
      </swiper>
   </ng-container>
   <ng-container *ngIf="!withSlider">
      <div  class="icon-cards__without-slider">
         <ng-container *ngFor="let card of cards">
            <div dssCard class="icon-cards__without-slider__item"
              [class.borderLeft]="withBorder">
               <div [ngClass]="{'circle': circle}">
                  <span dssIcon [name]='card?.icon' [size]='size'></span>
               </div>
               <p dssTypography type='caption' [innerHtml]="card?.title"></p>
            </div>
         </ng-container>
      </div>
   </ng-container>
</div>
