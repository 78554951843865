<dss-drawer
  [open]="open"
  (closeDrawer)="
    open = $event; onClosed.emit(true); closeModal(eventLabelCloseTop)
  "
>
  <dss-drawer-header>Que bom ter você aqui</dss-drawer-header>
  <dss-drawer-body>
    <ng-container>
      <label dssTypography type="caption">
        Para começar, precisamos do seu CPF
      </label>
      <form [formGroup]="form" class="form">
        <dss-form-field
          [isError]="(this.cpf.touched || this.cpf?.dirty) && !this.cpf?.valid"
        >
          <input
            trackingFill
            [eventLabel]="'input:cpf:cpf'"
            id="inputCPF"
            formControlName="cpf"
            type="text"
            inputmode="numeric"
            class="input-cpf"
            [imask]="cpfMask"
            [unmask]="false"
            #inputcpf
          />
          <label dssLabel>
            {{ cpfCMSInput?.label }}
          </label>
          <span
            *ngIf="
              (this.cpf.touched || this.cpf?.dirty) &&
              !this.cpf?.valid &&
              !cpf?.errors?.invalidCPF
            "
            dssError
          >
            {{ cpfCMSInput?.validator_required }}
          </span>
          <span *ngIf="!this.cpf?.valid && this.cpf.value !== ''" dssError>
            CPF inválido
          </span>
        </dss-form-field>
      </form>
    </ng-container>

    <mob-button
      [type]="'landing-page'"
      (actionClick)="validateCPF()"
      text="Continuar"
      [eventLabel]="'botao:continuar:cpf'"
      [action]="'next-top'"
      [disabled]="!this.form.valid"
    >
    </mob-button>
  </dss-drawer-body>
</dss-drawer>
