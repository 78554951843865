import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mob-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

    @Input() eventLabel = '';
    @Input() text = '';
    @Input() action = 'none';
    @Input() type = 'primary';
    @Input() disabled: boolean = false;
    @Input() styleClass = ''

    @Output() actionClick = new EventEmitter();

    constructor() { }
}
