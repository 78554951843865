<div class="mob-item">
    <div class="mob-icon">
        <div class="mob-circle-red">
            <span dssIcon [name]="icone" size="extra-large" class="mob-circle-red-icon"></span>
        </div>
    </div>
    <div class="mob-info">
        <div class="mob-title-2 mb-8">{{offer_title }}</div>
        <div class="mob-text-2">{{ offer_description }}</div>
    </div>
</div>
