import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DssButtonModule } from '@dss/components/button';
import { DssIconModule } from '@dss/components/icon';
import { DssListModule } from '@dss/components/list';
import { DssTypographyModule } from '@dss/components/typography';
import { IMaskModule } from 'angular-imask';
import { TrackingModule } from '../../directives/tracking.module';
import { FloatingButtonComponent } from './floating-button.component';

@NgModule({
  declarations: [ FloatingButtonComponent ],
  imports: [
    CommonModule,
    DssTypographyModule,
    DssButtonModule,
    DssIconModule,
    DssListModule,
    IMaskModule,
    TrackingModule
  ],
  exports:[FloatingButtonComponent]
})
export class FloatingButtonModule { }
