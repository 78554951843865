import { Injectable } from '@angular/core';
import { JSEncrypt } from 'jsencrypt';
import { environment } from '../../../environments/environment';

@Injectable()
export class SecurityService {

    encrypt(textPlain: string): string {
        let encrypt = new JSEncrypt();

        encrypt.setPublicKey(atob(environment.encryptionPEM));

        return encrypt.encrypt(textPlain);
    }

    decrypt(textEncrypted: string): string {
        return 'Não implementado!'
    }
}
