import { TaggingGtmConnector } from '@afe/tagging/gtm-connector';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { PAGE_VIEWS, TRACKING_EVENTS } from '../enum/tracking.enum';
import { StorageModule } from '../utils/storage.module';
import { MonitoringService } from './../services/monitoring.service';

@Directive({
  selector: '[trackingClick]'
})
export class TrackingClickDirective {
  @Input('eventLabel') eventLabel: string;

  @HostListener('click') onMouseEnter() {
    this.eventLabel = this.removeAcento(this.eventLabel);
    const event = {
      event: 'eventGA',
      nonInteraction: false,
      category: this.monitoring.category,
      action: TRACKING_EVENTS.CLICKED,
      label: this.eventLabel
    };

    this.taggingGtmConnector.tag(event);
  }

  public removeAcento(text) {
    if (text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
      text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
      text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
      text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
      text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
      text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
      text = text.split(" ").join("-");
      text = text.normalize('NFD').replace(/[\u0021-\u002C|\u002E-\u002F|\u003B-\u0040|\u0300-\u036f|\u00AE]/g, "")
      return text;
    }
  }

  constructor(private monitoring: MonitoringService, el: ElementRef, public taggingGtmConnector: TaggingGtmConnector) { }
}

@Directive({
  selector: '[trackingFill]'
})
export class TrackingFillDirective {
  @Input('eventLabel') eventLabel: string;

  @HostListener('blur') onMouseEnter() {
    const event = {
      event: 'eventGA',
      nonInteraction: false,
      category: this.monitoring.category,
      action: TRACKING_EVENTS.FILLED,
      label: this.eventLabel
    };
    this.taggingGtmConnector.tag(event);
  }

  constructor(private monitoring: MonitoringService, el: ElementRef, public taggingGtmConnector: TaggingGtmConnector) { }
}


@Directive({
  selector: '[trackingSelect]'
})
export class TrackingSelectDirective {
  @Input('eventLabel') eventLabel: string;

  @HostListener('click') onMouseEnter() {
    const event = {
      event: 'eventGA',
      nonInteraction: false,
      category: this.monitoring.category,
      action: TRACKING_EVENTS.SELECTED,
      label: this.eventLabel
    };
    this.taggingGtmConnector.tag(event);
  }

  constructor(private monitoring: MonitoringService, el: ElementRef, public taggingGtmConnector: TaggingGtmConnector) { }
}

@Directive({
  selector: '[trackingPage]'
})
export class TrackingPageDirective {
  @Input('eventLabel') eventLabel: string;

  constructor(el: ElementRef, public taggingGtmConnector: TaggingGtmConnector, public storage: StorageModule) {
    const pageView = this.storage.getPageView();
    if (pageView != this.eventLabel && this.eventLabel) {
      const event = {
        event: TRACKING_EVENTS.PAGEVIEW,
        page: PAGE_VIEWS[this.eventLabel]
      };

      this.storage.setPageView(this.eventLabel);
      this.taggingGtmConnector.tag(event);
    };
  }
}
