import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MonitoringService } from "../../services/monitoring.service";
import { onBlockScrollScreen } from "../../utils/block-scroll-screen";
import { removeAccentsAndPutInLowercase, replaceSpaceToHifen } from "../../utils/format";

@Component({
    selector: 'mob-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
  })
export class ModalComponent {

  @Input() title:string;
  @Input() actionButtonText:string='Ok, entendi';
  @Input() content:Array<TemplateStringsArray> | any;
  @Input() open:boolean=false;
  @Input() hasInteration:boolean=false;
  @Input() scrollTop: number = 0;
  @Input() eventLabelShow: string;
  @Input() eventLabelCloseTop: string;
  @Input() eventLabelCloseBottom: string;

  @Output() onClosed = new EventEmitter();
  stopPropagationEvent: boolean = false;

  constructor(private monitoringService: MonitoringService){}
  ngOnChanges() {
    if (this.open) {
     onBlockScrollScreen('hidden','fixed',this.scrollTop);
     this.stopPropagationEvent = false;
     if(this.eventLabelShow){
       const titleFormated = replaceSpaceToHifen(
        removeAccentsAndPutInLowercase(this.title)
        )
       this.monitoringService.show(`${this.eventLabelShow.replace('{{title}}',titleFormated)}`
       );
      }
    }
  }

  public onCloseModal(eventLabel?:string){
    onBlockScrollScreen('auto','relative',this.scrollTop);
    if(this.eventLabelCloseTop || this.eventLabelCloseBottom){
      const titleFormated = replaceSpaceToHifen(
        removeAccentsAndPutInLowercase(this.title)
      )
      this.monitoringService.click(`${eventLabel.replace('{{title}}',titleFormated)}`);
    }
  }
}
