export enum ComponentType {
  ITEM = '_onboardingpf_componente_item',
  BOX = '_onboardingpf_componente_box',
  LINKBUTTON = '_onboardingpf_componente_linkbutton',
}

export class CMSStep {
  public title: string = '';
  public subtitle: string = '';
  public id: string = '';
  public components: any = {};

  constructor(model?: any) {
    if (model) {
      this.title = model.title;
      this.subtitle = model.subtitle;
      this.id = model.id;
      this.components = this.extractComponents(model.components);
    }
  }

  extractComponents(components): any {
    let componentsParsed = {};

    if (components) {
      if (Array.isArray(components)) {
        components.map((c) => {
          const compKey = c.type;
          componentsParsed[c.component[compKey].name] = {
            ...c.component[compKey],
            type: c.type,
            id: c.id,
          };
        });
      } else {
        const compKey = components.type;
        componentsParsed[components.component[compKey].name] = {
          ...components.component[compKey],
          type: components.type,
          id: components.id,
        };
      }
    }

    return componentsParsed;
  }
}

export class CMSCustomComponent {
  name: string;
  id: string;
  type: string;
  eventLabel: string;
}
export class CMSInput extends CMSCustomComponent {
  hint: string;
  label: string;

  validator_required: string;
  validator_invalidFormat: string;
  validator_incorrectValue: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.name = model.name;
      this.id = model.id;

      this.hint = model._onboardingpf_componente_input_hint;
      this.label = model._onboardingpf_componente_input_label;
      this.validator_required =
        model._onboardingpf_componente_input_validator_required;
      this.validator_invalidFormat =
        model._onboardingpf_componente_input_validator_invalidFormat;
      this.validator_incorrectValue =
        model._onboardingpf_componente_input_validator_incorrectValue;
    }
  }
}

export class CMSButton extends CMSCustomComponent {
  label: string;
  isPrimary: boolean;

  constructor(model?: any) {
    super();
    if (model) {
      this.isPrimary = model._onboardingpf_componente_button_primary === 'Sim';
      this.label = model._onboardingpf_componente_button_label;
      this.name = model.name;
      this.id = model.id;
    }
  }
}

export class CMSBox extends CMSCustomComponent {
  title: string;
  description: string;
  icon: string = 'sys-004';
  hasBorder: boolean;
  constructor(model?: any) {
    super();
    if (model) {
      this.hasBorder = model._onboardingpf_componente_box_border === 'Sim';
      this.title = model._onboardingpf_componente_box_title;
      this.icon = model._onboardingpf_componente_box_icon
        ? model._onboardingpf_componente_box_icon
        : 'sys-004';
      this.description = model._onboardingpf_componente_box_description;
      this.name = model.name;
      this.id = model.id;
    }
  }
}

export class CMSItem extends CMSCustomComponent {
  title: string;
  description: string;
  icon: string;
  subtitle: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_componente_item_title;
      this.subtitle = model._onboardingpf_componente_item_subtitle;
      this.icon = model._onboardingpf_componente_item_icon;
      this.description = model._onboardingpf_componente_item_description;
      this.name = model.name;
      this.id = model.id;
    }
  }
}

export class CMSLinkButton extends CMSCustomComponent {
  label: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.label = model._onboardingpf_componente_linkbutton_label;
      this.name = model.name;
      this.id = model.id;
    }
  }
}

export class CMSFaqItem extends CMSCustomComponent {
  question: string;
  answer: string;
  isMono: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.question = model._onboardingpf_componente_faqitem_question;
      this.answer = model._onboardingpf_componente_faqitem_answer;
      this.id = model.id;
      this.isMono = model._onboardingpf_componente_faqitem_isMono;
    }
  }
}

export class CMSPackageSection extends CMSCustomComponent {
  title: string;
  icon: string;
  items: Array<CMSPackageOffer>;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_componente_section_title;
      this.icon = model._onboardingpf_componente_section_icon;
      this.items = this.getItems(model._onboardingpf_componente_section_items);
      this.name = model.name;
      this.id = model.id;
    }
  }

  getItems(components: Array<CMSCustomComponent> = []): Array<CMSPackageOffer> {
    return components
      ? Object.entries(Array.isArray(components) ? components : [components])
          .filter((c) => {
            return (
              c[1]['subtype'] &&
              c[1]['subtype'].indexOf('_onboardingpf_componente_package') === 0
            );
          })
          .map((item) => {
            return new CMSPackageOffer(item[1]);
          })
      : [];
  }
}

export class CMSPackageOffer extends CMSCustomComponent {
  offerId: string;
  title: string;
  value: string;
  features: Array<CMSItem>;
  oldValue?: string;
  additionalText?: string;
  isFeatured?: boolean;
  description: string;

  constructor(model?: any) {
    super();

    if (!model) {
      return;
    }

    this.title = model._onboardingpf_componente_package_title;
    this.value = model._onboardingpf_componente_package_value;
    this.offerId = model._onboardingpf_componente_package_offerId;
    this.oldValue = model._onboardingpf_componente_package_oldValue;
    this.additionalText = model._onboardingpf_componente_package_additionalText;
    this.features = this.getFeatures(
      model._onboardingpf_componente_package_features
    );
  }

  getFeatures(components: Array<CMSCustomComponent> = []): Array<CMSItem> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSItem(item[1]);
        })
      : [];
  }
}

export class CMSImage extends CMSCustomComponent {
  DescricaoImagemProposta: string;
  GrandeImagemProposta_bloblink_: string;
  MediaImagemProposta_bloblink_: string;
  PadraoImagemProposta_bloblink_: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.DescricaoImagemProposta = model.DescricaoImagemProposta;
      this.GrandeImagemProposta_bloblink_ =
        model.GrandeImagemProposta_bloblink_;
      this.MediaImagemProposta_bloblink_ = model.MediaImagemProposta_bloblink_;
      this.PadraoImagemProposta_bloblink_ =
        model.PadraoImagemProposta_bloblink_;
      this.name = model.name;
      this.id = model.id;
    }
  }
}

export class CMSBannerExperience extends CMSCustomComponent {
  title: string;
  subtitle: string;
  template: string;
  backgroundImage: CMSImage;
  size: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_componente_item_title;
      this.subtitle = model._onboardingpf_componente_item_subtitle;
      this.template = model._onboardingpf_componente_banner_experience_template;
      this.size = model._onboardingpf_componente_banner_experience_size;
      this.backgroundImage = new CMSImage(
        model._onboardingpf_componente_banner_experience_background
      );
      this.name = model.name;
      this.id = model.id;
    }
  }
}

export class CMSProductCardExperience extends CMSCustomComponent {
  productBox: Array<ProductBoxExperience>;

  constructor(model?: any) {
    super();
    if (model) {
      this.productBox = this.getProducts(
        model._onboardingpf_componente_productbox_experience
      );
      this.name = model.name;
      this.id = model.id;
    }
  }

  getProducts(
    components: Array<CMSCustomComponent> = []
  ): Array<ProductBoxExperience> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new ProductBoxExperience(item[1]);
        })
      : [];
  }
}
export class ProductBoxExperience extends CMSCustomComponent {
  title: string;
  value: string;
  color: string;
  benefits: Array<CMSProductBoxBenefitsExperience>;
  titleSeeMore: string;
  details: Array<CMSExperienceSeeMore>;
  offerCode: string;
  badgeMessage?: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_productbox_name;
      this.value = model._onboardingpf_productbox_value;
      this.color = model._onboardingpf_productbox_color;
      this.benefits = this.getBenefits(model._onboardingpf_productbox_benefits);
      this.details = this.getSeeMore(model._onboardingpf_productbox_details);
      this.offerCode = model._onboardingpf_productbox_offerCode;
      this.badgeMessage =
        model._onboardingpf_componente_productbox_experience_badgeMessage;
      this.titleSeeMore =
        model._onboardingpf_componente_productbox_experience_seeMore;
      this.name = model.name;
      this.id = model.id;
    }
  }
  getBenefits(
    components: Array<CMSCustomComponent> = []
  ): Array<CMSProductBoxBenefitsExperience> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSProductBoxBenefitsExperience(item[1]);
        })
      : [];
  }
  getSeeMore(
    components: Array<CMSCustomComponent> = []
  ): Array<CMSExperienceSeeMore> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSExperienceSeeMore(item[1]);
        })
      : [];
  }
}

export class CMSProductBoxBenefitsExperience extends CMSCustomComponent {
  benefit: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.benefit = model._onboardingpf_componente_item_description;
    }
  }
}

export class CMSSectionHeaderExperience extends CMSCustomComponent {
  title: string;
  subtitle: string;
  // backgroundColor:string;
  // contentAlignment:string;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_componente_item_title;
      this.subtitle = model._onboardingpf_componente_item_subtitle;
      // this.backgroundColor = model._onboardingpf_componente_header_experience_backgroundColor ;
      // this.contentAlignment = model._onboardingpf_componente_header_experience_contentAlignment ;
      this.name = model.name;
      this.id = model.id;
    }
  }
}

export class CMSIconCardsExperience extends CMSCustomComponent {
  iconCardsContent: Array<CMSContentIconCardsExperience>;

  constructor(model?: any) {
    super();
    if (model) {
      this.iconCardsContent = this.getIconCard(
        model._onboardingpf_componente_iconcards_cards
      );
      this.name = model.name;
      this.id = model.id;
    }
  }
  getIconCard(
    components: Array<CMSCustomComponent> = []
  ): Array<CMSContentIconCardsExperience> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSContentIconCardsExperience(item[1]);
        })
      : [];
  }
}
export class CMSContentIconCardsExperience extends CMSCustomComponent {
  title: string;
  icon: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_componente_card_title;
      this.icon = model._onboardingpf_componente_card_icon;
    }
  }
}

export class CMSGaleryCardsCarroussel extends CMSCustomComponent {
  galeryCards: Array<CMSGaleryCardsExperience>;

  constructor(model?: any) {
    super();
    if (model) {
      this.galeryCards = this.getCardsGalery(
        model._onboardingpf_componente_galery_experience
      );
      this.name = model.name;
      this.id = model.id;
    }
  }
  getCardsGalery(
    components: Array<CMSCustomComponent> = []
  ): Array<CMSGaleryCardsExperience> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSGaleryCardsExperience(item[1]);
        })
      : [];
  }
}

export class CMSGaleryCardsExperience extends CMSCustomComponent {
  image: CMSImage;
  title: string;
  benefits: Array<CMSExperienceSeeMore>;
  details: Array<CMSExperienceSeeMore>;

  constructor(model?: any) {
    super();
    if (model) {
      this.image = new CMSImage(
        model._onboardingpf_componente_galery_experience_image
      );
      this.title = model._onboardingpf_componente_galery_experience_title;
      this.benefits = model._onboardingpf_componente_galery_experience_benefits;
      this.details = this.getSeeMore(
        model._onboardingpf_componente_productCard_benefits
      );
      this.name = model.name;
      this.id = model.id;
    }
  }
  getSeeMore(
    components: Array<CMSCustomComponent> = []
  ): Array<CMSExperienceSeeMore> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSExperienceSeeMore(item[1]);
        })
      : [];
  }
}

export class CMSExperienceSeeMore extends CMSCustomComponent {
  description: string;
  icon: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.icon = model._onboardingpf_componente_item_icon;
      this.description = model._onboardingpf_componente_item_description;
    }
  }
}

export class CMSSectionHeaderCardExperience extends CMSCustomComponent {
  title: string;
  subtitle: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_componente_item_title;
      this.subtitle = model._onboardingpf_componente_item_subtitle;
      this.name = model.name;
      this.id = model.id;
    }
  }
}

export class CMSImageWithCardsExperience extends CMSCustomComponent {
  cards: Array<CMSContentImageWithCardsExperience>;
  imageUrl: CMSImage;

  constructor(model?: any) {
    super();
    if (model) {
      this.cards = this.getCards(
        model._onboardingpf_componente_iconcards_cards
      );
      this.imageUrl = new CMSImage(
        model._onboardingpf_componente_banner_celular
      );
      this.name = model.name;
      this.id = model.id;
    }
  }
  getCards(
    components: Array<CMSCustomComponent> = []
  ): Array<CMSContentImageWithCardsExperience> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSContentImageWithCardsExperience(item[1]);
        })
      : [];
  }
}
export class CMSSectionText extends CMSCustomComponent {
  backgroundColor: string;
  defaultAlignment: string;
  fontColor: string;
  fontSize: string;
  text: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.backgroundColor =
        model._onboardingpf_componente_sectiontext_backgroundColor;
      this.defaultAlignment =
        model._onboardingpf_componente_sectiontext_defaultAlignment;
      this.fontColor = model._onboardingpf_componente_sectiontext_fontColor;
      this.fontSize = model._onboardingpf_componente_sectiontext_fontSize;
      this.text = model._onboardingpf_componente_sectiontext_text;
      this.name = model.name;
      this.id = model.id;
    }
  }
}
export class CMSFaqQuestionsItens extends CMSCustomComponent {
  title: string;
  items: Array<CMSFaqItem>;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_componente_item_title;
      this.items = this.getItems(model._onboardingpf_faqitens_questions);
      this.name = model.name;
      this.id = model.id;
    }
  }
  getItems(components: Array<CMSCustomComponent> = []): Array<CMSFaqItem> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSFaqItem(item[1]);
        })
      : [];
  }
}
export class CMSColorTitleBenefits extends CMSCustomComponent {
  color: string;
  items: Array<CMSItem>;
  title: string;

  constructor(model?: any) {
    super();
    if (model) {
      this.title = model._onboardingpf_componente_title_subtitle;
      this.color = model._onboardingpf_productbox_color;
      this.items = this.getItems(model._onboardingpf_componente_list_items);
      this.name = model.name;
      this.id = model.id;
    }
  }
  getItems(components: Array<CMSCustomComponent> = []): Array<CMSItem> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSItem(item[1]);
        })
      : [];
  }
}

export class CMSContentImageWithCardsExperience extends CMSCustomComponent {
  icon: string;
  title: string;
  headline?: string;
  description: string;
  content: Array<CMSExperienceSeeMore>;

  constructor(model?: any) {
    super();
    if (model) {
      this.icon = model._onboardingpf_componente_card_icon;
      this.title = model._onboardingpf_componente_card_title;
      this.headline = model._onboardingpf_componente_card_headline;
      this.description = model._onboardingpf_componente_card_description;
      this.content = this.getSeeMore(model._onboardingpf_productbox_details);
      this.name = model.name;
      this.id = model.id;
    }
  }
  getSeeMore(
    components: Array<CMSCustomComponent> = []
  ): Array<CMSExperienceSeeMore> {
    return components
      ? Object.entries(
          Array.isArray(components) ? components : [components]
        ).map((item) => {
          return new CMSExperienceSeeMore(item[1]);
        })
      : [];
  }
}

export class CMSConfig extends CMSCustomComponent {

  name: string;
  value?: string;

  constructor(model?: any) {
      super();
      if (model) {
          this.name = model._onboardingpf_componente_settings_flag_name;
          this.value = model._onboardingpf_componente_settings_flag_value;
          this.id = model.id;
      }
  }
}

export class CMSBannerImage extends CMSImage {

  constructor(model?: any) {
    super();
    if (model) {
      this.name = model.name;
      this.id = model.id;

      this.DescricaoImagemProposta = model['campo-banner']['DescricaoImagemProposta'];
      this.GrandeImagemProposta_bloblink_ =
        model['campo-banner']['GrandeImagemProposta_bloblink_'];
      this.MediaImagemProposta_bloblink_ = model['campo-banner']['MediaImagemProposta_bloblink_'];
      this.PadraoImagemProposta_bloblink_ =
        model['campo-banner']['PadraoImagemProposta_bloblink_'];
    }
  }
}

export class CMSSectionItem extends CMSCustomComponent {

  header: CMSBannerImage;
  content: CMSItem;
  inputCpf: CMSInput;
  button: CMSButton;

  landingPageInfo: string;
  productType: 'landing' | 'landing-select';

  constructor(model?: any) {
    super();
    if (model) {
        this.name = model.name;
        this.id = model.id;
        this.landingPageInfo = model._onboardingpf_componente_landing_cpf_section_parametro;
        this.productType = model._onboardingpf_componente_landing_cpf_section_tipo;

        const itemsList: Record<string, string>[] = model._onboardingpf_componente_section_items;

        const pageContentItems = {
          '_onboardingpf_componente_button': 'button',
          '_onboardingpf_componente_banner': 'header',
          '_onboardingpf_componente_input': 'inputCpf',
          '_onboardingpf_componente_item': 'content'
        };

        const cmsClasses = {
          '_onboardingpf_componente_button': CMSButton,
          '_onboardingpf_componente_banner': CMSBannerImage,
          '_onboardingpf_componente_input': CMSInput,
          '_onboardingpf_componente_item': CMSItem
        };
        
        // atribui os componentes CMS às props header, content, inputCpf e button
        itemsList.forEach(item => {
          this[pageContentItems[item.subtype]] = new cmsClasses[item.subtype](item)
        });

    }
  }

}

export class CMSSectionList extends CMSCustomComponent {

  itemsList: CMSSectionItem[];

  constructor(model?: any) {
      super();

      if (model) {
          this.name = model.name;
          this.id = model.id;
          this.itemsList = this.getSectionItems(model._onboardingpf_componente_landing_cpf_container?._onboardingpf_componente_section_items);
      }
  }

  getSectionItems(components: CMSCustomComponent[] = []): CMSSectionItem[] {
  
      return components ? Object.entries(Array.isArray(components) ? components : [components])
          .filter(c => {
              return c[1]['subtype'] && c[1]['subtype'].indexOf('_onboardingpf_componente_landing_cpf_section') === 0;
          })
          .map(item => {              
              return new CMSSectionItem(item[1]);
          }) : [];
  }
}