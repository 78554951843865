import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CMSInput } from "../../../shared/model/cms-model";
import { cpfMask } from "../../../shared/utils/masks";
import { CustomValidators } from "../../../shared/validators/customValidations";
import { onBlockScrollScreen } from '../../utils/block-scroll-screen';
import { removeAccentsAndPutInLowercase, replaceSpaceToHifen } from '../../utils/format';
import { MonitoringService } from './../../../shared/services/monitoring.service';

@Component({
    selector: 'mob-modal-cpf',
    templateUrl: './modalCPF.component.html',
    styleUrls: ['./modalCPF.component.scss']
  })
export class ModalCPFComponent implements OnChanges {

  @Input() title:string;
  @Input() actionButtonText:string='Continuar';
  @Input() content:string;
  @Input() open:boolean=false;

  @Input() cpfCMSInput:CMSInput;
  @Input() scrollTop: number = 0;

  @Input() eventLabelShow: string;
  @Input() eventLabelCloseTop: string = '';
  @Input() eventLabelCloseBottom: string;

  @Output() onClosed = new EventEmitter();
  @Output() onValidateCPF = new EventEmitter();

  cpfMask = cpfMask;

  stopPropagationEvent: boolean = false;

  public form: FormGroup;
  public VO: any;
  public formSubmitted:boolean=false;
  cpfIsValid: (number: string, strict?: boolean) => boolean;

  //   /** CPF Form Control */
  get cpf() {
    return this.form.get('cpf');
  }

  constructor(
    private monitoringService: MonitoringService
  ){

    this.form = new FormGroup({
      cpf: new FormControl('',[
        Validators.required, CustomValidators.invalidCPF
      ])
    });

  }
  ngOnChanges() {
    if (this.open) {
      this.buildValidation();
      this.monitoringService.show('modal:cpf');
      onBlockScrollScreen('hidden','fixed',this.scrollTop);
    }
  }

  public buildValidation() {
    this.cpf.setValidators([
        Validators.required,
        Validators.minLength(14),
        CustomValidators.invalidCPF,
    ]);
}

  validateCPF(){
    const input = document.getElementById('inputCPF') as HTMLInputElement;

    input.value = input.value.replace(/[^a-z0-9]/gi,'');

    this.onValidateCPF.emit(input.value);
  }

  public closeModal(eventLabel?:string){
    this.cpf.reset();
    onBlockScrollScreen('auto','relative',this.scrollTop);
      const titleFormated = replaceSpaceToHifen(
        removeAccentsAndPutInLowercase(this.title)
      )
      this.monitoringService.click(`${eventLabel.replace('{{title}}',titleFormated)}`);
    }
  }

