import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DssButtonModule } from '@dss/components/button';
import { DssIconModule } from '@dss/components/icon';
import { DssListModule } from '@dss/components/list';
import { DssTypographyModule } from '@dss/components/typography';
import { ListComponent } from './list.component';

@NgModule({
  declarations: [ListComponent],
  imports: [
    CommonModule,
    DssTypographyModule,
    DssButtonModule,
    DssIconModule,
    DssListModule
  ],
  exports:[ListComponent]
})
export class ListModule { }
