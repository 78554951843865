<div class="gallery full-container" >
    <div class="gallery__pictures">
          <swiper #swiper class="mySwiper" [config]="config" (swiper)="slideChangeCards($event)" (slideChange)="slideChangeCards($event)"
          >
          <ng-template swiperSlide *ngFor="let item of items" class="gallery__pictures__picture">
            <img alt=""
                 [src]="item?.image?.GrandeImagemProposta_bloblink_ | normalizeImage : isMock : 'select/cards/' + item?.name"
                 class="gallery__pictures__picture__image" />
          </ng-template>
        </swiper>
    </div>
    <div class="gallery__items"  dssScrollbar style='overflow: auto; height: 380px;'>
        <dss-accordion class="gallery_component" #accordionGallery>
            <dss-accordion-item
            class="gallery__items__item"
            *ngFor="let item of items; let i = index"
            [isExpanded]="items[i] === items[currentItem]"
            (opened)="openAccordionCards(item)"
            >
                <p dssAccordionItemTitle type='h2'>{{item?.title}}</p>
                <ng-container dssAccordionItemBody >
                  <div class="gallery__item__benefits">
                      <div class="benefit__item" *ngFor="let benefit of item?.benefits">
                          <span dssIcon [name]='benefit?._onboardingpf_componente_item_icon || benefit?.icon'></span>
                          <span dssTypography type='caption' color='gray' [innerHtml]="benefit?._onboardingpf_componente_item_title || benefit?.title"></span>
                    </div>
                    <button trackingClick eventLabel="link:saiba-mais:{{item?.title}}" class='dss-webview benefit__see-more' (click)="onSeeMoreClick(item)" dssButton>Saiba mais</button>
                  </div>
                </ng-container>
            </dss-accordion-item>
        </dss-accordion>
    </div>
</div>
