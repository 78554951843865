import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { urlConfig } from '../../../../config/url.config';
import { CMSConfig, CMSStep } from '../../model/cms-model';

@Injectable({
    providedIn: 'root',
})
export class CmsService {

    cmsFlags$ = new BehaviorSubject(null)
    private shallowId = 'onboarding-pf';
    private url: string;
    private httpOptions = () => {

        let options = {};

        if(urlConfig.cms_x_staging === 'true'){
            options = { ...options,
            headers: new HttpHeaders({
                'X-Staging':  'x'
              })
            }
        }

        options = { ...options,
            params: new HttpParams().set(
                'gw-app-key',urlConfig.app_key)
            };

        return options;
      };


    constructor(public https: HttpClient) {
        this.url = urlConfig.url_hub_cms;
    }

    public getPages(): Observable<any> {
        let pagesURL = `${this.url}/shallow/@shallow_id`;
        pagesURL = pagesURL.replace('@shallow_id', this.shallowId);
        return this.https.get(pagesURL, this.httpOptions());
    }

    public getDetail(stepId: string): Observable<any> {
        let detailsURL = `${this.url}/step/${stepId}`;
        return this.https.get(detailsURL, this.httpOptions());
    }

    public getPageDetail(pageId) {

        try{

            return this.getPages().pipe(
                mergeMap((data)=> {
                    const pages = data.flow.pages;
                    let pageFound;

                    if(Array.isArray(pages)){
                        pageFound = pages.find(p=>p.id === pageId);
                    }
                    else{
                        pageFound = pages;
                    }

                    if(typeof pageFound === 'undefined'){
                        return this.handleCMSPageError(null);
                    }
                    else{
                        return this.getDetail(pageFound.steps).pipe(
                            map((data)=> { return { step: new CMSStep(data.step) }}),
                            catchError((err) => {
                                return this.handleCMSPageError(err);
                            }));
                    }
                }),
                catchError((err) => {
                    return this.handleCMSPageError(err);
                }));
        }
        catch(err){
            return this.handleCMSPageError(err);
        }
    }

    handleCMSPageError(err:any):Observable<any>{
        return of({ step: false, error: Error('CMS_PAGE_ERROR') });
    }

    parseFeatureFlags(components:any){
      if(components){
        Object.entries(components).forEach((config:any)=>{
          const objConfig = new CMSConfig(config[1]);
          components[config[0]] = objConfig;
        });
        return components;
      }
      else{
        return false;
      }
    }
}
