export enum STEP_ID {
    IDENTIFICATION = 'identification',
    IDENTITY_RECOVERY = 'identity-recovery',
    CLIENT_NAME = 'client-name',
    CLIENT_DATA = 'client-data',
    CLIENT_ADDRESS = 'client-address',
    FACE_VALIDATION = 'face-validation',
    DOCUMENT_VALIDATION = 'document-validation',
    PRODUCTS_AQUISITION = 'products-aquisition',
    SECRECT_ACCESS = 'secret-access',
    TERMS = 'terms',
    FINISIH = 'finish'
}
