<div class="image-with-cards full-container" [style.grid-gap.px]="gapValue">
  <ng-container *ngIf="innerWidth > 1000">
    <div class="image-with-cards__column">
      <div class="card__item" *ngFor="let card of leftCards">
        <div class="card__item__header">
          <div
            class="card__item__header__{{
              !isIconBlack ? 'icon-wrapper__red' : 'icon-wrapper__black'
            }}"
          >
            <span dssIcon [name]="card?.icon" size="medium"></span>
          </div>
          <div
            class="card__item__header__headline"
            *ngIf="card?.headline"
            [innerHTML]="card?.headline"
          ></div>
        </div>
        <h4
          class="card__item__title"
          dssTypography
          type="figure4"
          [innerHtml]="card?.title"
        ></h4>
        <span
          class="card__item__description"
          dssTypography
          type="caption"
          [innerHtml]="card?.description"
        >
        </span>
        <ng-container *ngIf="hasMoreDetails">
          <button
            trackingClick
            eventLabel="link:saiba-mais:combo-santander"
            class="dss-display-flex dss-justify-content-between"
            *ngIf="card?.content[0]"
            dssButton
            (click)="onSeeMoreClick(card)"
          >
            Saiba mais
          </button>
        </ng-container>
      </div>
    </div>

    <div class="image-with-cards__column">
      <img [src]="imageUrl" alt="" />
    </div>
    <div class="image-with-cards__column">
      <div class="card__item" *ngFor="let card of rigthCards">
        <div class="card__item__header">
          <div
            class="card__item__header__{{
              !isIconBlack ? 'icon-wrapper__red' : 'icon-wrapper__black'
            }}"
          >
            <span dssIcon [name]="card?.icon" size="medium"></span>
          </div>
          <div
            class="card__item__header__headline"
            *ngIf="card?.headline"
            [innerHTML]="card?.headline"
          ></div>
        </div>
        <h4
          class="card__item__title"
          dssTypography
          type="figure4"
          [innerHtml]="card?.title"
        ></h4>
        <span
          class="card__item__description"
          dssTypography
          type="caption"
          [innerHtml]="card?.description"
        ></span>
        <ng-container *ngIf="hasMoreDetails">
          <button
            class="dss-display-flex dss-justify-content-between"
            *ngIf="card?.content[0]"
            dssButton
            (click)="onSeeMoreClick(card)"
          >
            Saiba mais
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
<div>
  <swiper [config]="swiperConfig" *ngIf="innerWidth < 1000">
    <ng-template
      swiperSlide
      class="mobile_image-with-cards__column"
      *ngFor="let card of allCards"
    >
      <div class="mobile__card__item">
        <div class="mobile__card__item__header">
          <div
            class="mobile__card__item__header__{{
              !isIconBlack ? 'icon-wrapper__red' : 'icon-wrapper__black'
            }}"
          >
            <span dssIcon [name]="card?.icon" size="medium"></span>
          </div>
          <div
            class="mobile__card__item__header__headline"
            *ngIf="card?.headline"
            [innerHTML]="card?.headline"
          ></div>
        </div>
        <h4
          class="mobile__card__item__title"
          dssTypography
          type="figure4"
          [innerHtml]="card?.title"
        ></h4>
        <span
          class="mobile__card__item__description"
          dssTypography
          type="caption"
          [innerHtml]="card?.description"
        ></span>
        <ng-container *ngIf="hasMoreDetails">
          <button
            class="dss-display-flex dss-justify-content-between"
            *ngIf="card?.content[0]"
            dssButton
            (click)="onSeeMoreClick(card)"
          >
            Saiba mais
          </button>
        </ng-container>
      </div>
    </ng-template>
  </swiper>
</div>
<div *ngIf="innerWidth < 1000" class="mobile_image">
  <img [src]="imageUrl" alt="" />
</div>
