<dss-drawer [open]='open' 
(closeDrawer)='open = $event;onClosed.emit(true);!stopPropagationEvent && onCloseModal(eventLabelCloseTop)'
>
   <dss-drawer-header>{{title}}</dss-drawer-header>
   <dss-drawer-body>
     <ng-container *ngIf="hasInteration">
        <div *ngFor="let contentSeeMore of content" class="benefit__item">
          <span dssIcon *ngIf="contentSeeMore?.icon !== 'null' && contentSeeMore?.icon" [name]='contentSeeMore?.icon' ></span>
          <span dssIcon *ngIf="contentSeeMore?.icon === 'null'" name='checkmark' [ngStyle]="{'color':'#739e42'}"></span>
          <p class='dss-mb-1' [innerHtml]="contentSeeMore?.description"></p>
        </div>
     </ng-container>
     <ng-container *ngIf="!hasInteration">
        <div  class="benefit__item">
          <span dssIcon *ngIf="content?.icon !== 'null' && content?.icon" [name]='content?.icon' ></span>
          <p class='dss-mb-1' [innerHtml]="content?.description"></p>
        </div>
     </ng-container>
     <div class="space"></div>
   </dss-drawer-body>
   <dss-drawer-footer>
     <button 
     dssFilledButton 
     (click)="onClosed.emit(true);onCloseModal(eventLabelCloseBottom);stopPropagationEvent = true">{{actionButtonText}}</button>
   </dss-drawer-footer>
 </dss-drawer>