<dss-dialog [open]="open">
    <dss-dialog-header>
      <header class="dialog-header dss-display-flex dss-justify-content-between">
        <h1 dssTypography type="h1">{{ title }}</h1>
        <span dssIcon name="close-1px" size="large" (click)="close.emit(true)" style="cursor: pointer"></span>
      </header>
    </dss-dialog-header>

    <dss-dialog-body>
      <p>{{ text }}</p>
    </dss-dialog-body>
</dss-dialog>
