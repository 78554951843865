import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CMSInput } from "../../../model/cms-model";
import { cpfMask } from "../../../utils/masks";
import { CustomValidators } from "../../../validators/customValidations";

@Component({
  selector: 'app-input-cpf',
  templateUrl: './input-cpf.component.html',
  styleUrls: ['./input-cpf.component.scss']
})

export class InputCpfComponent implements OnInit{
  @Input() cpfCMSInput:CMSInput;
  @Output() valueChange = new EventEmitter();

  cpfMask = cpfMask;

  public form: FormGroup;

  get cpf() {
    return this.form.get('cpf');
  }

  ngOnInit() {
    this.form = new FormGroup({
      cpf: new FormControl('',[
        Validators.required, CustomValidators.invalidCPF
      ])
    });
    this.buildValidation();
  }

  public buildValidation() {
   this.cpf.setValidators([
        Validators.required,
        Validators.minLength(14),
        CustomValidators.invalidCPF,
    ]);
  }

  validateCPF(){
    this.valueChange.emit(this.form.controls['cpf'].value.replace(/[^a-z0-9]/gi,''))
  }
}
