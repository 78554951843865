import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
export interface AdsenseModel{
  key:string;
  value?:string;
  classType:string;
}
/**
 * @export
 * @class AdsenseService
 */
@Injectable()
export class AdsenseService {

  constructor() { }

  /**
   * Reads URL parameters received on browser
   * @param params
   * @returns
   */
  public getParametersAdsense(params: Params):AdsenseModel[] {
    const adsense:AdsenseModel[] = [];
    const keys = Object.getOwnPropertyNames(params);

    keys.forEach(keyName => {
      adsense.push({
        key: keyName ,
        value: this.sanitizeAdsenseValue(params[keyName]),
        classType: 'AdsenseVO'
      });
    });

    return adsense;
  }

  /**
   * Gets only one value to be defined and adsense value
   * @param values  an array or a string object passed as a value from specific URL parameter
   * @returns first string value from array or same value received if it wasn't an array object
   */
  sanitizeAdsenseValue(values:string|string[]=[]):string{
    return Array.isArray(values) ? values[0]:values;
  }
}
