<div class="expandable-list full-container" >
   <dss-accordion>
      <dss-accordion-item (opened)="taggingItemAccordion(item)" [isExpanded]='item?.isSelected' class="gallery__items__item" *ngFor="let item of items" >
        <p dssAccordionItemTitle [ngStyle]="{ 'font-size' : '1rem'}" type='H3' (click)="item.isSelected = !item.isSelected">{{item?.question}} </p>
        <ng-container dssAccordionItemBody>
         <p dssTypography [ngStyle]="{ 'font-weight' : '300'}" type='body' color='gray' [innerHtml]="item?.answer"></p>
        </ng-container>
      </dss-accordion-item>
  </dss-accordion>
</div>
