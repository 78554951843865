import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DssTypographyModule } from '@dss/components/typography';
import { LoadingComponent } from './loading.component';

@NgModule({
  declarations: [LoadingComponent],
  imports: [
    CommonModule,
    DssTypographyModule
  ],
  exports:[LoadingComponent]
})
export class LoadingModule { }
