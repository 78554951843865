enum TRACKING_EVENTS {
    CLICKED = 'clicou',
    SELECTED = 'selecionou',
    SHOW = 'exibiu',
    OPEN = 'abriu',
    FILLED = 'preencheu',
    PAGEVIEW = 'pageview',
}

enum TRACKING_COMPONENTS {
    CONTINUE_BUTTON = 'botao:continuar',
    ACCEPT_CARD_BUTTON = 'botao:aceitar-cartao',
    REFUSE_CARD_BUTTON = 'botao:recusar-cartao',
    ACCEPT_SCP_BUTTON = 'botao:aceitar-scp',
    REFUSE_SCP_BUTTON = 'botao:recusar-scp',
    CLOSE_SCP_MODAL_BUTTON = 'botao:fechar-scp',
    SEE_MORE_SCP_BUTTON = 'botao:ver-mais-scp',
    ALLOW_CAMERA_ACCESS_BUTTON = 'botao:permitir-acesso',
    NOT_MY_NUMBER_BUTTON = 'botao:nao-e-meu-numero',
    DONT_KNOW_MY_ZIPCODE_BUTTON = 'botao:nao-seu-meu-cep',
    CHANGE_CLIENT_NAME_BUTTON = 'botao:mudar-nome',
    BUTTON_LABEL_SAMPLE = 'botao:experimente',
    LINK_LABEL_KNOW_MORE = 'link:saiba-mais:acesso-todos-beneficios',
    BUTTON_LABEL_OPEN_ACCOUNT = 'botao:abra-sua-conta:contrate-agora',
    BUTTON_LABEL_OPEN_ACCOUNT_BANNER = 'botao:abra-sua-conta:banner',
    SHOW_MODAL_HIRE_LABEL = 'modal:contrate-pacote-select-plus',
    BUTTON_CLOSE_MODAL_HIRE_LABEL = 'botao:fechar:contrate-pacote-select-plus',
    BUTTON_I_UNDERSTOOD_MODAL_HIRE_LABEL = 'botao:ok-entendi:contrate-pacote-select-plus',
    SHOW_MODAL_GALLERY_LABEL = 'modal:detalhes-{{title}}',
    BUTTON_CLOSE_MODAL_GALLERY_LABEL = 'botao:fechar-{{title}}',
    BUTTON_I_UNDERSTOOD_MODAL_GALLERY_LABEL = 'botao:ok-entendi:{{title}}',
    TALK_TO_US = 'botao:fale-com-a-gente',
    CPF_INPUT = 'input:cpf',
    MAIL_INPUT = 'input:email',
    CELLPHONE_INPUT = 'input:telefone',
    ZIPCODE_INPUT = 'input:cep',
    ADDRESS_INPUT = 'input:endereco',
    ADDRESS_NUMBER_INPUT = 'input:numero',
    ADDRESS_NO_NUMBER_INPUT = 'input:sem-numero',
    ADDRESS_COMPLEMENT_INPUT = 'input:complemento',
    ADDRESS_NEIGHBORHOOD_INPUT = 'input:bairro',
    ADDRESS_CITY_INPUT = 'input:cidade',
    ADDRESS_STATE_INPUT = 'input:estado'
}

enum PAGE_VIEWS {
    'identificacao' = '/pf/funil/contas/abertura-de-conta/dados-pessoais/step-1b',
    'token' = '/pf/funil/contas/abertura-de-conta/token',
    'status-conta-token' = '/pf/funil/contas/abertura-de-conta/token-status',
    'confirmar-nome' = '/pf/funil/contas/abertura-de-conta/confirmar-nome',
    'mudar-nome' = '/pf/funil/contas/abertura-de-conta/mudar-nome',
    'criar-senha' = '/pf/funil/contas/abertura-de-conta/senha-multicanal',
    'produtos-e-termos' = '/pf/funil/contas/abertura-de-conta/produtos-e-termos',
    'feedback' = '/pf/funil/contas/abertura-de-conta/abertura-de-conta-em-analise/envio',
    'erro' = '/pf/erro/conta-corrente/erro-generico',
    'ja-possui-conta' = '/pf/erro/conta-corrente/ja-possui-conta',
    'conta-indisponivel' = '/pf/erro/conta-corrente/conta-indisponivel',
    'erro-de-servico' = '/pf/erro/conta-corrente/erro-de-servico',
    'erro-sem-conexao' = '/pf/erro/conta-corrente/erro-sem-conexao',
    'faq' = '/pf/funil/contas/abertura-de-conta/duvidas-frequentes',
    'status-conta-email' = '/pf/funil/contas/abertura-de-conta/status-conta-email',
    'landing' = '/pf/funil/contas/abertura-de-conta/vantagens-em-ter-uma-conta-corrente-no-santander',
    'sucess' = '/pf/funil/contas/abertura-de-conta/solicitacao-aprovada',
    'accountHolder' = '/landing/perfil-existente',
    'dados-pessoais' = '/pf/funil/contas/abertura-de-conta/completar-cadastro/step-3a',
    'endereco' = '/pf/funil/contas/abertura-de-conta/endereco/step-3b',
    'selfie-biometria' = '/pf/funil/contas/abertura-de-conta/biometria',
    'confirmacao' = '/pf/funil/contas/abertura-de-conta/confirmacao',
    'capturar-selfie' = '/pf/funil/contas/abertura-de-conta/capturar-selfie',
    'envio-de-documentos' = '/pf/funil/contas/abertura-de-conta/envio-de-documentos',
    'capturar-documento' = '/pf/funil/contas/abertura-de-conta/capturar-documentos',
    'ver-documento' = '/pf/funil/contas/abertura-de-conta/ver-documento',
    'oferta' = '/pf/funil/contas/abertura-de-conta/ofertas-de-conta/step-2',
    'cartao' = '/pf/funil/contas/abertura-de-conta/ofertas-de-cartao',
    'seguro-cartao' = '/pf/funil/contas/abertura-de-conta/seguro-cartao',
    'experiencias' = '/pf/funil/contas/abertura-de-conta/experiencias',
    'select' = '/pf/funil/abertura-de-conta/contaselect',
    'landing-cpf' = '/pf/funil/contas/abertura-de-conta/select'
}

enum CLIENT_TYPE {
    'MONO' = 'monoprodutista',
    'OPEN' = 'mar-aberto',
    'CARD' = 'conta-cartao',
    'FOPA' = 'folha-de-pagamento'
}

const  TH_EVENT_LABELS  = {
    '1':'provavelmente-aprovado',
    '2':'zona-cinzenta',
    '3':'provavelmente-negado',
    '4':'aprovado-com-oferta-garantida'
}


export{
    TRACKING_COMPONENTS, TRACKING_EVENTS, PAGE_VIEWS, CLIENT_TYPE, TH_EVENT_LABELS
}
