import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class StepAccessGuard implements CanActivate {
    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean {

        console.log('data:',next.data, ' going to:',state.url);

        //TODO: Verify current status of roposal
        return true
    }
  }
