import { CMS_PAGE_ID } from '../enum/cms-page-id.enum';
import { STEP_ID } from '../enum/step-id.enum';

// stepId: 123, // usado para identificar o step do backend
// path: 'pagina-indisponivel', // usado para enviar ao monitoramento da aplicação (pageView do Google Analytics e Dynatrace)
// url: 'pagina-indisponivel', // usado para navegação do usuário, é o que aparece na URL do navegador
// showHeader: true, // controla exibição do cabeçalho na página
// cmsId:'123' // usado para recuperar os textos e imagens de cada página no resolver

export const ROUTES = {
  error: {
    stepId: null,
    path: 'erro',
    url: 'erro',
    showHeader: true,
    cmsId: ''
  },

  landing: {
    stepId: STEP_ID.IDENTIFICATION,
    path: '',
    url: '',
    cmsId: CMS_PAGE_ID.pageLanding,
    category: 'portal:contapf'
  },
  landingCpf: {
    stepId: STEP_ID.IDENTIFICATION,
    path: 'cpf',
    url: 'cpf',
    cmsId: CMS_PAGE_ID.pageLandingCpf,
    category: 'portal:contapf'
  },
  landingExperience: {
    stepId: STEP_ID.IDENTIFICATION,
    path: 'combo',
    url: 'combo',
    cmsId: CMS_PAGE_ID.pageLandingExperience,
    category: 'portal:abra-sua-conta'
  },
  landingSelect: {
    stepId: STEP_ID.IDENTIFICATION,
    path: 'conta-select',
    url: 'conta-select',
    cmsId: CMS_PAGE_ID.pageLandingSelect,
    category: 'portal:conta-select'
  },
  errorAccountHolder: {
    stepId: null,
    path: 'perfil-existente',
    url: 'perfil-existente',
    showHeader: true,
    cmsId: ''
  },
  errorAccountOpen: {
    stepId: null,
    path: 'proposta-aprovada',
    url: 'proposta-aprovada',
    showHeader: true,
    cmsId:''
  },
  errorAccountRejected: {
    stepId: null,
    path: 'erro-abertura-conta',
    url: 'erro-abertura-conta',
    showHeader: true,
    cmsId: ''
  },

  errorGeneric: {
    stepId: null,
    path: 'erro-de-servico',
    url: 'erro-de-servico',
    showHeader: true,
    cmsId: '',
    title:'Desculpe',
    subtitle:'Estamos fazendo alguns ajustes',
    icon:'alert'
  },

  errorOffline: {
    stepId: null,
    path: 'erro-sem-conexao',
    url: 'erro-sem-conexao',
    showHeader: true,
    cmsId: ''
  },
}

export function getRoutesByStep(stepId: STEP_ID): IRoute[] {
  const result: IRoute[] = [];

  Object.entries(ROUTES).filter(
    route => {
      return route[1].stepId === stepId
    }
  ).forEach(entry => {
    const objKey = entry[0];
    const objValue = entry[1];
    const obj: IRoute = {};

    obj[objKey] = objValue;

    result.push(obj);
  });

  return result;
}

export interface IRoute {
  stepId?: STEP_ID;
  path?: string;
  url?: string;
  cmsId?: CMS_PAGE_ID
}
