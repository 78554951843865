import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DssTypographyModule } from '@dss/components/typography';
import { TrackingModule } from '../../directives/tracking.module';
import { ButtonModule } from '../forms/button/button.module';
import { PageComponent } from './page.component';

@NgModule({
  declarations: [PageComponent],
  imports: [
    CommonModule,
    DssTypographyModule,
    TrackingModule,
    ButtonModule,
  ],
  exports:[PageComponent]
})
export class PageModule { }
