import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DssButtonModule } from '@dss/components/button';
import { TrackingModule } from '../../../../shared/directives/tracking.module';
import { ButtonComponent } from './button.component';
@NgModule({
  declarations: [ButtonComponent],
  imports: [
    CommonModule,
    DssButtonModule,
    TrackingModule
  ],
  exports:[ButtonComponent]
})
export class ButtonModule { }
