import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyDomService } from './lazy-dom-observer.service';
import { LazyDomDirective } from './lazy-dom.directive';

@NgModule({
  declarations: [LazyDomDirective],
  imports: [
    CommonModule
  ],
  exports: [LazyDomDirective],
  providers: [LazyDomService]
})
export class LazyDomModule { }
