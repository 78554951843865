<div class="title-with-items full-container" [ngStyle]="{ 'backgroundColor' :'#f7fafb'}">
   <div class="title-with-items__column">
      <h2 dssTypography type='figure2' class="title-with-items__title" [innerHtml]="title"></h2>
   </div>
   <div class="title-with-items__column title-with-items__list ">
      <div class="title-with-items__item" *ngFor="let card of items; let i = index">
         <span class="title-with-items__item__dots" >{{i+1}}</span>
         <div class="title-with-items__item__text">
            <h4 dssTypography type='figure4' class="title-with-items__item__text__headline" [innerHtml]="card?.title"></h4>
            <p dssTypography type='caption' color='gray' class="title-with-items__item__text__content" [innerHtml]="card?.subtitle"></p>
         </div>
    </div>
   </div>
</div>
