import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES } from '../app/shared/utils/routes';
import { CMS_PAGE_ID } from './shared/enum/cms-page-id.enum';
import { StepPageResolver } from './shared/resolvers/step-page.resolver';

const routes: Routes = [
    {
        path: ROUTES.landingSelect.url,
        loadChildren: () => import('./features/landing-select/landing-select.module').then(m => m.LandingSelectModule)
    },
    {
        path: ROUTES.landingExperience.url,
        redirectTo: ROUTES.landing.url,
    },
    {
        path: ROUTES.landingCpf.url,
        loadChildren: () => import('./features/landing-cpf/landing-cpf.module').then(m => m.LandingCpfModule)
    },
    {
        path: ROUTES.landing.url,
        loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule)
    },
    {
        path: ROUTES.errorGeneric.url,
        data:{
            route: {
                path: 'erro-de-servico'
            },
            cmsId: CMS_PAGE_ID.pageError,
            icon:'alert',
            error: 'serviceError',
            buttonText_1:'Voltar para o inicio',
            title: 'Desculpe!',
            subtitle:'Estamos fazendo alguns ajustes',
            redirectUrl:'{{landing}}'
        },
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
    },
    {
      path: ROUTES.error.url,
      data:{
          route: {
              path: 'erro'
          },
          cmsId: CMS_PAGE_ID.pageError,
          icon:'alert',
          error: 'serviceError',
          title: 'Não foi possível continuar',
          subtitle:'Estamos fazendo alguns ajustes, retorne aqui em alguns instantes.'
      },
      loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
    {
        path:'pagina-nao-encontrada',
        data:{
            route: {
                path: 'pagina-nao-encontrada'
            },
            icon:'error',
            error: 'notFound',
            buttonText_1:'Voltar para o inicio',
            title: 'Ops, essa página não existe :/',
            subtitle:'Volte à página inicial e abra sua conta agora!',
            redirectUrl:'{{landing}}'
        },
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
    },
    {
        path:"**",
        redirectTo:'pagina-nao-encontrada',
        pathMatch:"full"
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
