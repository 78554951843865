import { Injectable, ViewContainerRef } from '@angular/core';
import { Fold } from './lazy-dom.directive';

@Injectable({
  providedIn: 'any',
})
export class LazyDomService {

  /**
    * Renderiza o próximo elemento da lista de @param folds e adiciona um {IntersectionObserver}
    * @param {Array<Fold>} param folds - Array com as referências e o view status de cada dobra
    * @return {IntersectionObserver} Retorna o observer para a primeira execução na diretiva
    * @author Pedro Guilherme <X274637@santander.com.br>
    */
  public foldObserver = (folds: Array<Fold>): IntersectionObserver => {
    let foldIndex = 1;
    const observer = new IntersectionObserver((entries) => {
      const nextFoldIndex = foldIndex + 1;
      const nextFold = folds[nextFoldIndex];

      if (foldIndex > folds.length - 2) return;
      entries.forEach((entry) => {
        if (entry.isIntersecting && !nextFold.isVisible) {
          nextFold.isVisible = true;
          const nextViewContainer: ViewContainerRef = nextFold.viewContainer;
          nextViewContainer.createEmbeddedView(nextFold.templateRef);

          const nextElement: HTMLElement = (
            nextFold.viewContainer.get(0) as any
          ).rootNodes[0];
          observer.observe(nextElement);

          foldIndex++;
        }
      });
    });
    return observer;
  };
}
