import { TaggingGtmConnector } from '@afe/tagging/gtm-connector';
import { Injectable } from '@angular/core';
import { THCreditStatusResponse } from '@mob/mob-lib-cpf-validator/lib/cpf-validation.model';
import {
  PAGE_VIEWS,
  TH_EVENT_LABELS,
  TRACKING_EVENTS,
} from '../enum/tracking.enum';
import { StorageModule } from '../utils/storage.module';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private categoryData: string;

  constructor(
    public taggingGtmConnector: TaggingGtmConnector,
    public storage: StorageModule
  ) {}

  public get category(): string {
    return this.categoryData;
  }

  public set category(value: string) {
    this.categoryData = value;
  }

  public send(pageName: string, fullPath?: string) {
    try {
      const currentPageView = this.storage.getPageView();
      const hasNewPageView = !!pageName && currentPageView !== pageName;
      const hasNewFullPath = !!fullPath && currentPageView !== fullPath;

      if (hasNewFullPath || hasNewPageView) {
        const event = {
          event: "pageviewGA",
          page: fullPath || PAGE_VIEWS[pageName],
          customDimensions: this.storage.getDimensions()
        };

        this.storage.setPageView(fullPath || pageName);
        window.dataLayer.push(event);
        // this.taggingGtmConnector.tag(event);
      }
    } catch (e) {
      // send erro
    }
  }

  /**
  * Validate and dispatch event GA if have no undefined value on attributes
  * @param event
  */
  sendValidEvent(event){
    try{
        event = {
            ...event, customDimensions: this.storage.getDimensions()
        }
        const { category, action, label } = event;
        if(category && action && label){
            this.taggingGtmConnector.tag(event);
        }
    }
    catch(e){}
}

  productImpression(promotions: Array<any>) {
    const event = {
      event: 'productImpression',
      ecommerce: {
        currencyCode: 'BRL',
        impressions: promotions,
      },
    };

    try {
      this.sendGaEvent(event);
    } catch (error) {}
  }


  productImpressionClick(promotion: Array<any>) {
    const event = {
      event: 'productClick',
      ecommerce: {
        click: {
          products: promotion,
        },
      },
    };

    try {
      this.sendGaEvent(event);
    } catch (error) {}
  }

  sendGaEvent(event) {
    window.dataLayer.push(event);
  }

  public click(eventClick) {
    try {
      const event = {
        event: 'eventGA',
        nonInteraction: false,
        category: this.category,
        action: TRACKING_EVENTS.CLICKED,
        label: `${eventClick}`,
      };
      this.sendValidEvent(event);
    } catch (e) {
      // send erro
    }
  }

  selected(eventClick) {
    try {
      const event = {
        event: 'eventGA',
        nonInteraction: false,
        category: this.category,
        action: TRACKING_EVENTS.SELECTED,
        label: `${eventClick}`,
      };
      this.sendValidEvent(event);
    } catch (e) {
      // send erro
    }
  }

  filled(eventClick) {
    try {
      const event = {
        event: 'eventGA',
        nonInteraction: false,
        category: this.category,
        action: TRACKING_EVENTS.FILLED,
        label: `${eventClick}`,
      };
      this.sendValidEvent(event);
    } catch (e) {
      // send erro
    }
  }

  show(eventClick) {
    const event = {
      event: 'eventGA',
      nonInteraction: true,
      category: this.category,
      action: TRACKING_EVENTS.SHOW,
      label: `${eventClick}`,
    };

    try {
      this.sendValidEvent(event);
    } catch (e) {}
  }

  /**
   * Send GA Event only for TH call
   * @param label event label based on TH motor indicator
   * @param category event category based on LP origin
   */
  private showTH(label: string, lpOrigin: string) {
    const event = {
      event: 'eventGA',
      nonInteraction: true,
      category: `portal:${lpOrigin}`,
      action: TRACKING_EVENTS.SHOW,
      label,
    };
    try {
      this.sendValidEvent(event);
    } catch (e) {}
  }

  /**
   * Format and send GA event with data returned by TH Motor
   * @param response payload from TH service
   * @param lpOrigin landing page identifier
   */
  sendCheckStatusTH(response: THCreditStatusResponse, lpOrigin: string) {
    const idFlowSanitized = response?.idflow || 'motor-mob-sem-retorno';
    if (idFlowSanitized) {
      this.storage.setMotorMob({ idFlow: idFlowSanitized, flow: lpOrigin });
      this.showTH(idFlowSanitized, lpOrigin);
    }
  }

  //Verifica se os objetos são iguais
  //Usado para evitar duplicidade ao disparar um evento de GA
  isEqual(systemCustom, sendCustom) {
    let keySystemCustom = Object.keys(systemCustom.customDimensions);
    let keySendCustom = Object.keys(sendCustom);

    if (keySystemCustom.length !== keySendCustom.length) return false;

    let diferent = keySystemCustom.some((key) => {
      return systemCustom.customDimensions[key] !== sendCustom[key];
    });

    return !diferent;
  }

  public customEvent(customDimension, reset = false) {
    if(!reset) this.setStorageDimension(customDimension);
  }

  setStorageDimension(customDimension) {
    this.storage.setDimesions({...customDimension});
  }

  sendDimensionProductName() {
    this.customEvent({dimension40: 'Conta PF'})
  }

  sendDimension8(cpf: string) {
    this.customEvent({ dimension8: cpf })
  }
}
