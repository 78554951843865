import { TaggingModule } from '@afe/tagging';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CPFValidatorModule } from '@mob/mob-lib-cpf-validator';
import { taggingConfig } from '../config/tracing.config';
import { urlConfig } from '../config/url.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './shared/components/header/header.module';
import { LoadingModule } from './shared/components/loading/loading.module';
import { StepAccessGuard } from './shared/guards/step-access.guard';
import { AdsenseService } from './shared/services/adsense.service';
import { HeaderService } from './shared/services/header.service';
import { MonitoringService } from './shared/services/monitoring.service';
import { StartService } from './shared/services/start.service';
import { SharedModule } from './shared/shared.module';
import { DssDialogModule } from '@dss/components/dialog';
import { CmsService } from './shared/services/cms/cms.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    HeaderModule,
    LoadingModule,
    DssDialogModule,
    TaggingModule.forRoot(taggingConfig),
    BrowserAnimationsModule,
    CPFValidatorModule.forRoot({
      validationURL: urlConfig.url_hub_cpf,
      appsURL:{
        fopa:urlConfig.url_fopa,
        link:urlConfig.url_opf,
        mob:urlConfig.url_mob
      },
      encriptionPEM: urlConfig.encryptionPEM
    })
  ],
  providers: [
      HeaderService,
      StepAccessGuard,
      AdsenseService,
      MonitoringService,
      StartService,
      CmsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
