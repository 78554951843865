import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import SwiperCore, { A11y, Autoplay, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AdsenseService } from './shared/services/adsense.service';
import { CmsService } from './shared/services/cms/cms.service';
import { HeaderService } from './shared/services/header.service';
import { MonitoringService } from './shared/services/monitoring.service';
import { StartService } from './shared/services/start.service';
import { StorageModule } from './shared/utils/storage.module';
import { urlConfig } from '../../src/config/url.config';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly _destroy$ = new Subject<void>();

  title = 'spa-mob-landing-page';
  themeChangedSub: any;
  wasOffline: boolean = false;

  public type = 'text/javascript';
  public showFloatButton = false;
  private adsense;

  constructor(
    private cmsService: CmsService,
    public storage: StorageModule,
    public headerService: HeaderService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private adsenseService: AdsenseService,
    public monitoring: MonitoringService,
    public startService: StartService) {

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.headerService.isPageLoading = true;
        this.headerService.loadingTextTitle = 'Carregando';
      }

      if (event instanceof NavigationEnd) {

        if (this.activatedRoute.firstChild.children.length > 0) {
          const routeData = this.activatedRoute.firstChild.children[0].snapshot.data;
          this.monitoring.category = routeData.route?.category;
        }
        this.headerService.isPageLoading = false;
      }
    });
  }

  ngOnInit() {
    this.storage.landingURLParameters = window.location.search;
    if(this.activatedRoute){
      this.activatedRoute.queryParams
      .pipe(takeUntil(this._destroy$))
      .subscribe((params) => {
        this.saveAdense(params);
      });
    }
  }

  ngAfterViewInit() {


    this.startService.refreshConfigs().subscribe((flags)=>{
      this.cmsService.cmsFlags$.next(flags);
      if(flags){
        const { _onboardingpf_componente_settings_flag_GA,
          _onboardingpf_componente_settings_flag_hotjar,
          _onboardingpf_componente_settings_flag_optimize,
          _onboardingpf_componente_settings_flag_float_button
        } = flags;

        if(_onboardingpf_componente_settings_flag_float_button){
          const flagFloatButton = _onboardingpf_componente_settings_flag_float_button.value;
          this.storage.floatButtonFlag = flagFloatButton;
        }
        if(_onboardingpf_componente_settings_flag_GA ? _onboardingpf_componente_settings_flag_GA.value === 'Y' :false){
          this.loadGoogleAnalytics();
        }
        if(_onboardingpf_componente_settings_flag_hotjar? _onboardingpf_componente_settings_flag_hotjar.value === 'Y':false){
          this.loadHotjar();
        }
        if(_onboardingpf_componente_settings_flag_optimize? _onboardingpf_componente_settings_flag_optimize.value === 'Y':false){
          this.loadAdobeTarget();
        }
      }
      else{
        this.loadGoogleAnalytics();
        this.loadHotjar();
        this.loadAdobeTarget();
      }
    },
    (error)=>{
      this.loadGoogleAnalytics();
      this.loadHotjar();
      this.loadAdobeTarget();
    });

  }

  saveAdense(queryParams: Params) {
    this.adsense = this.adsenseService.getParametersAdsense(queryParams);
    this.storage.adsense = this.adsense;
  }

  loadGoogleAnalytics(){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `(function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      var n = d.querySelector("[nonce]");
      n && j.setAttribute("nonce", n.nonce || n.getAttribute("nonce"));
      f.parentNode.insertBefore(j, f);
      })(
        window,
        document,
        "script",
        "dataLayer",
        "` + environment.id_google_tag_manager + `"
      );
      `;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  loadAdobeTarget(){
    const script = document.createElement('script');
    script.src = `${urlConfig.keyAdobeTarget}`;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  loadHotjar (){
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = `(function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 1191888, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`;
      document.getElementsByTagName('head')[0].appendChild(script);
  };

  ngOnDestroy(){
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
