import { Component, Input } from "@angular/core";
import { CMSFaqItem } from '../../../shared/model/cms-model';
import { MonitoringService } from '../../services/monitoring.service';
import { removeAccentsAndPutInLowercase, replaceSpaceToHifen } from '../../utils/format';

@Component({
  selector: 'mob-expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss']
})
export class ExpandableListComponent {
  @Input() items: Array<CMSFaqItem> = [];

  constructor(
    private monitoringService: MonitoringService
  ) { }

  taggingItemAccordion(event: { answer: string, id: string, isMono: any, question: string }) {
    const labelTagging = replaceSpaceToHifen(removeAccentsAndPutInLowercase(`dropdown:${event.question}`));
    this.monitoringService.selected(labelTagging)
  }
}
