import { AbstractControl, ValidatorFn } from '@angular/forms';
export class CustomValidators {
  private static validCPFFormat: RegExp = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;

  static validateSingle(value: string, rule: any) {
    value = !value ? '' : String(value);
    if (!rule) {
      return true;
    }
    return rule instanceof RegExp ? rule.test(value) : rule(value);
  }

  /**
   * Valida CPF
   *  @type {ValidatorFn}
   */
  static get invalidCPF(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const fieldValue: string = control.value || '';
      if (fieldValue) {
        const invalidFormat = !this.validateSingle(
          fieldValue,
          this.validCPFFormat
        );
        const invalidValue = !this.isValidCPF(fieldValue);
        return invalidFormat || invalidValue
          ? { invalidCPF: { fieldValue } }
          : null;
      } else {
        return null;
      }
    };
  }

  static isValidCPF(cpf) {
    if (typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[\s.-]*/gim, '');
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999'
    ) {
      return false;
    }
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return false;
    return true;
  }
}
