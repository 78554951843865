import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mob-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  @Input() title = '';
  @Input() text = '';
  @Input() open = false;

  @Output() close = new EventEmitter<boolean>();

  ngOnInit(): void { }

}
