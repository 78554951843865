/** Solução paliativa para corrigir comportamento da modal de CPF, ao ser fechada não remove o overflow-y: hiddin da tag body causando o travamento do scroll da tela.
 * @param over enable or disabled screen scroll
 * @param pos position of element in screen
 * @param offset capture position of element in screen
 */
export function onBlockScrollScreen(
  over: string, 
  pos: string, 
  offset: number
  ){
 const element = <HTMLElement>document.getElementsByTagName('body')[0];
 element.style.overflowY = `${over}`;
 element.style.position = `${pos}`;
 element.style.pointerEvents = 'all';
 window.scrollTo({
   top: offset,
   behavior: 'smooth'
 })
}

export function iOS() {
  return navigator.userAgent.includes("iPhone")
  || (navigator.userAgent.includes("iPhone") && "ontouchend" in document)
}