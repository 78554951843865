import { Injectable } from '@angular/core';
import { CpfValidatorService } from '@mob/mob-lib-cpf-validator';
import { CPFValidationResultModel, THCreditStatusResponse } from '@mob/mob-lib-cpf-validator/lib/cpf-validation.model';
import { catchError, tap } from 'rxjs/operators';
import { StorageModule } from '../utils/storage.module';
import { MonitoringService } from './monitoring.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { CMS_PAGE_ID } from '../enum/cms-page-id.enum';
import { CmsService } from './cms/cms.service';
@Injectable()
export class StartService {

    public isValidationDisabled:boolean = false;

    constructor(
        private validationService: CpfValidatorService,
        private storage: StorageModule,
        private monitoringService: MonitoringService,
        public cmsService: CmsService
    ) {}

    validateCPF(cpf:string, offerCode:string):Observable<CPFValidationResultModel>{

        return  this.validationService.validate(cpf,offerCode);
    }

    validateTHStatus(cpf:string, page:string):Observable<THCreditStatusResponse>{
        return this.validationService.validateTH(cpf).pipe(
          tap((response) => {
            setTimeout(() => {
              this.monitoringService.sendCheckStatusTH(response, page);
            },500)
          },
          catchError((error) => {
            this.monitoringService.sendCheckStatusTH({
              idflow:null
            }, page);
            return error;
          }))
        );
    }

    /**
     * Clear storage but saves important keys to GA and onboarding
     */
    clearStorage() {
       // Save important storage data before cleaning all
      const adsense = this.storage.adsense;
      const landingOrigin = this.storage.landingOrigin;
      const landingURL = this.storage.landingURLParameters;
      const cookieAggreed = this.storage.cookieAgreed;
      const floatButton = this.storage.floatButtonFlag;

      // Clear to avoid wrong category and pageview on resume step
      this.storage.clear();
      // Adding again  to retrieve on /onboarding journey
      this.storage.adsense = adsense;
      this.storage.landingURLParameters = landingURL;
      this.storage.landingOrigin = landingOrigin;
      this.storage.cookieAgreed = cookieAggreed;
      this.storage.floatButtonFlag = floatButton;
    }

    saveStorage(document:string, encrypteDocument:string, flowId:string, lpOrigin:string) {
      this.storage.encrypteDocument = encrypteDocument;
      this.storage.document = document;
      this.storage.flowId = flowId;
      this.storage.landingOrigin = lpOrigin;
    }

    refreshConfigs() {
      return this.cmsService.getPageDetail(CMS_PAGE_ID.pageSettings).pipe(
        map((settings:any) => {

          if(settings?.step?.components ) {
            const configs = this.cmsService.parseFeatureFlags(settings?.step?.components);
            this.isValidationDisabled =  configs?._onboardingpf_componente_settings_flag_disablePages?.value === 'Y';
            return configs;
          } else {
            this.isValidationDisabled = false;
            return settings;
          }

        }));
    }
}
