import { Component, Input } from '@angular/core';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'mob-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input() show:boolean = false;
  @Input() title?:string = 'Carregando';
  @Input() subtitle?:string = 'Por favor, aguarde ...';

  constructor(public headerService: HeaderService) { }

}
