import { EventEmitter, Injectable } from '@angular/core';
import { StorageModule } from '../utils/storage.module';


const themeSettings = {
    active:'light',
    light:{

    },
    dark:{

    }
}

@Injectable({
    providedIn: 'root',
  })
export class HeaderService {

    private _display:boolean = true;
    private _title:string;
    private _subtitle:string;
    public showBackButton:boolean = false;
    public isPageLoading:boolean = false;
    public loadingTextTitle:string='Carregando';
    public loadingTextSubtitle:string='Por favor, aguarde ...';
    public isMobile:boolean = false;
    public themeChanged= new EventEmitter();

    constructor(private storage: StorageModule){ }
    get display(){
        return this._display;
    }

    get title(){
       return this._title;
    }
    get subtitle(){
        return this._subtitle;
    }

    get themeColor(){
        return this.storage.getTheme().active;
    }

    hide(){
        this._display = false;
    }

    show(){
        this._display = true;
    }

    updateValues(values){
        const { title,subtitle } = values;
        this._title = title;
        this._subtitle = subtitle;
    }

    setDarkTheme(){
        this.storage.setTheme({ ...themeSettings, active:'dark' });
        this.themeChanged.emit(true);
    }

    setLightTheme(){
        this.storage.setTheme({ ...themeSettings, active: 'light' });
        this.themeChanged.emit(true);
    }
}
