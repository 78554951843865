import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TrackingClickDirective, TrackingFillDirective, TrackingPageDirective, TrackingSelectDirective } from '../directives/tracking.directive';

@NgModule({
  declarations: [
        TrackingClickDirective,
        TrackingFillDirective,
        TrackingSelectDirective,
        TrackingPageDirective ],
  imports: [
    CommonModule
  ],
  exports:[
    TrackingClickDirective,
    TrackingFillDirective,
    TrackingSelectDirective,
    TrackingPageDirective ]
})
export class TrackingModule { }
