export enum CMS_PAGE_ID {
    pageLanding = 'pagina-landing',
    pageLandingCpf = 'pagina-landing-cpf',
    pageLandingExperience = 'pagina-landing-experience',
    pageLandingSelect = 'pagina-landing-select',
    pageStart = 'onboarding-start',
    pageError = 'pagina-error',
    pageIdentification = 'pagina-indentification',
    pageIdentityRecovery = 'pagina-identity-recovery',
    pageClientNameView = 'pagina-client-name-view',
    pageClientNameEdit = 'pagina-client-name-edit',
    pageClientData = 'pagina-client-data',
    pageClientAddress = 'pagina-client-address',
    pageToken ='pagina-token',
    pageFraudeFaceBiometry ='pagina-fraud-face-biometry',
    pageFraudeFaceBiometryCapture ='pagina-fraud-face-biometry-capture',
    pageFraudeFaceSelfie ='pagina-fraud-face-selfie',
    pageFraudeFaceSelfieCapture ='pagina-fraud-face-selfie-capture',
    pageFraudeFaceSelfieViewer ='pagina-fraud-face-selfie-viewer',
    pageDocument ='pagina-document-capture',
    pageDocumentCapture ='pagina-document-capture',
    pageDocumentViewer ='pagina-document-viewer',
    pageSettings ='pagina-settings',
    paginaAccountHolder = 'pagina-account-holder'
}
