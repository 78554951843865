<ng-container *ngIf="type === 'secondary'">
    <button dssOutlineButton (click)="actionClick.emit({action:action})" 
    trackingClick [eventLabel]="eventLabel" trackingClick [eventLabel]="eventLabel" >{{text}}</button>
</ng-container>
<ng-container *ngIf="type === 'primary'">
    <button dssFilledButton (click)="actionClick.emit({action:action})" 
    trackingClick [eventLabel]="eventLabel" trackingClick [eventLabel]="eventLabel" >{{text}}</button>
</ng-container>
<ng-container *ngIf="type === 'landing-page'">
    <button dssFilledButton [class]="styleClass" (click)="actionClick.emit({action:action})" 
    trackingClick [eventLabel]="eventLabel" [disabled]="disabled" trackingClick [eventLabel]="eventLabel">{{text}}</button>
</ng-container>