
export function formatEmail(value: string) {
  const email = String(value || '');
  if (email === '') {
    return email;
  }
  return email.toLowerCase();
}

export function formatStatusPhone(phone: string): string {
  let a = 5, b = 7;
  if (phone.length === 10) { a = 4; b = 6; }
  return `(${phone.substr(0, 2)})${phone.substr(2, a)}-${phone.substr(b, 4)}`;
}

export function replaceSpaceToHifen(string) {
  if (string) {
    return string.split(" ").join("-")
  }
}

export function removeAccentsAndPutInLowercase(value: string) {
  if (value) {
    return (value.normalize('NFD').replace(/[\u0021-\u002C|\u002E-\u002F|\u003B-\u0040|\u0300-\u036f|\u00AE]/g, "")).toLowerCase()
  }
}

export function stringMoneyToNumber(value: string) {
  if (value) {
    return Number(value.split(".").join("").replace("R$", "").replace(",", "."));
  }
}
