import { NgModule } from '@angular/core';
import { ProposalResponse } from '../model/proposal-response';

interface Storage {
  readonly length: number;
  clear(): void;
  getItem(key: string): string | null;
  key(index: number): string | null;
  removeItem(key: string): void;
  setItem(key: string, data: string): void;
  [key: string]: any;
  [index: number]: string;
}

@NgModule()
export class StorageModule {
  readonly PREFIX = 'stdr#';
  readonly ERROR = 'Error';
  readonly SUGGESTION = 'suggestion';
  readonly KEY_SELECTED_OFFER = 'selectedOffer';
  readonly KEY_PAGES = 'pages';
  readonly KEY_THEME = 'theme';
  readonly KEY_DOCUMENT = 'documento';
  readonly KEY_ENCRYPTED_DOCUMENT = 'encrypted_document';
  readonly KEY_ADSENSE = 'adsense';
  readonly DIMENSION_KEY = 'dimensionKey';
  readonly KEY_BTN_FLOAT = 'btnFloat';
  readonly KEY_MOTOR_MOB = 'motorMob';
  readonly KEY_LANDING_ORIGIN = 'landing-origin';
  readonly KEY_LANDING_PARAMETERS = 'parameters';
  readonly KEY_FLOWID = 'flowId';
  readonly KEY_COOKIE_AGREE = 'isCookieAgreed';
  readonly KEY_DISABLE_VALIDATION = 'disableValidation';

  public storage: Storage;

  constructor() {
    this.storage = sessionStorage;
  }

  appKey(key: string): string {
    return this.PREFIX + key;
  }

  /**
   * Adiciona um elemento no storage.
   * @param key chave
   * @param value valor a adicionar
   */
  put(key: string, value: any) {
    this.storage.setItem(this.appKey(key), value);
  }

  /**
   * Obtem um valor do storage.
   * @param key chave
   * @return o valor ou null caso nao exista.
   */
  get(key: string, _default: any = ''): string {
    let keyValue = this.storage.getItem(this.appKey(key));
    return keyValue ? keyValue : _default;
  }

  /**
   * Remove um valor do storage.
   * @param key chave
   * @return o valor ou null caso nao exista.
   */
  remove(key: string): string {
    const value = this.get(key);
    this.storage.removeItem(this.appKey(key));
    return value;
  }

  clear() {
    this.storage.clear();
  }

  setPageView(pageview: string) {
    this.put('pageview', pageview);
  }

  getPageView(): string {
    return this.get(this.PREFIX, 'pageview');
  }

  getTheme() {
    return JSON.parse(this.get(this.KEY_THEME));
  }

  setTheme(theme: any) {
    this.put(this.KEY_THEME, JSON.stringify(theme));
  }

  get document(): string {
    return this.get(this.KEY_DOCUMENT, '');
  }

  set document(value:string){
    this.put(this.KEY_DOCUMENT,value);
  }

  get landingURLParameters(): string {
    return this.get(this.KEY_LANDING_PARAMETERS, '');
  }

  set landingURLParameters(value:string){
    this.put(this.KEY_LANDING_PARAMETERS,value);
  }


  get encrypteDocument(): string {
    return this.get(this.KEY_ENCRYPTED_DOCUMENT, '');
  }

  set encrypteDocument(value:string){
    this.put(this.KEY_ENCRYPTED_DOCUMENT,value);
  }


  get landingOrigin(): string {
    return this.get(this.KEY_LANDING_ORIGIN, '');
  }

  set landingOrigin(value:string){
    this.put(this.KEY_LANDING_ORIGIN,value);
  }

  get cookieAgreed(): string {
    return this.get(this.KEY_COOKIE_AGREE, '');
  }

  set cookieAgreed(value:string){
    this.put(this.KEY_COOKIE_AGREE,value);
  }

  set flowId(value:string){
    this.put(this.KEY_FLOWID,value);
  }



  hasDocument() {
    return this.document.trim().length > 0;
  }

  get adsense() {
    return JSON.parse(this.get(this.KEY_ADSENSE, '[]'));
  }

  set adsense(value:[]){
    this.put(this.KEY_ADSENSE, JSON.stringify(value));
  }

  setDimesions(value) {
    this.put(this.DIMENSION_KEY, JSON.stringify(value));
  }

  getDimensions() {
    return JSON.parse(
      this.get(this.DIMENSION_KEY, 'null') === 'null'
        ? 'false'
        : this.get(this.DIMENSION_KEY)
    );
  }

  set floatButtonFlag(value) {
    this.put(this.KEY_BTN_FLOAT, value);
  }
  get floatButtonFlag() {
    return this.get(this.KEY_BTN_FLOAT, 'null') === 'null'
      ? 'N'
      : this.get(this.KEY_BTN_FLOAT);
  }

  setMotorMob(value: { idFlow: string, flow: string }) {
    this.put(this.KEY_MOTOR_MOB, JSON.stringify(value));
  }

  /**
   * Sets config key to disable CPF validation
   */
  set disableValidation(value:boolean) {
    this.put(this.KEY_DISABLE_VALIDATION, value);
  }

  /**
   * Get config key to disable CPF validation
   */
  get disableValidation(){
    return this.get(this.KEY_DISABLE_VALIDATION,'N') === 'Y';
  }
}
