import { Pipe, PipeTransform } from '@angular/core';
type AcceptedExtensions = 'png' | 'jpg' | 'gif' | 'jpeg' | 'svg' | 'webp';
@Pipe({
  name: 'normalizeImage',
})
export class NormalizeImagePipe implements PipeTransform {
  transform(
    imagePath: string,
    isMock: boolean,
    relativePath: string,
    extension?: AcceptedExtensions
  ): string {
    if (!isMock) return imagePath;
    return `assets/img/${relativePath}.${extension ? extension : 'webp'}`;
  }
}
