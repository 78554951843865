import { Component, Input } from "@angular/core";

@Component({
    selector: 'mob-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss']
  })
export class SectionHeaderComponent {
  @Input() title:string;
  @Input() subtitle:string;
  @Input() backgroundColor:string='#fff';
  @Input() contentAlignment:string='center';
}
