import { Component, Input } from "@angular/core";
import { CMSItem } from '../../../shared/model/cms-model';

@Component({
    selector: 'mob-title-with-list',
    templateUrl: './title-with-list.component.html',
    styleUrls: ['./title-with-list.component.scss']
  })

export class TitleWithItemsComponent {
  @Input() items:Array<CMSItem>=[];
  @Input() title:string;
  @Input() backgroundColor:string='#f7fafb';
}
