import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DssAccordionModule, DssButtonModule, DssCardModule, DssDrawerModule, DssFormFieldModule, DssTypographyModule } from '@dss/components';
import { DssBadgeModule } from '@dss/components/badge';
import { DssCarouselModule } from '@dss/components/carousel';
import { DssHeaderModule } from '@dss/components/header';
import { DssScrollbarModule } from '@dss/components/scrollbar';
import { IMaskModule } from 'angular-imask';
import { SwiperModule } from 'swiper/angular';
import { CookiesAgreementModule } from '../shared/components/cookies-agreement/cookies-agreement.module';
import { BannerModule } from './components/banner/banner.module';
import { ImageCentereWithCardsComponent } from './components/centered-image-with-cards/centered-image-with-cards.component';
import { ExpandableListComponent } from './components/expandable-list/expandable-list.component';
import { FloatingButtonModule } from './components/floating-button/floating-button.module';
import { ButtonModule } from './components/forms/button/button.module';
import { InputCpfComponent } from './components/forms/input-cpf/input-cpf.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { HeaderModule } from './components/header/header.module';
import { IconCardsComponent } from './components/icon-cards/icon-cards.component';
import { ListModule } from './components/list/list.module';
import { ModalComponent } from './components/modal/modal.component';
import { ModalCPFComponent } from './components/modalCPF/modalCPF.component';
import { OfferItemComponent } from './components/offer-item/offer-item.component';
import { PageModule } from './components/page/page.module';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SectionTextComponent } from './components/section-text/section-text.component';
import { TitleWithItemsComponent } from './components/title-with-list/title-with-list.component';
import { TrackingModule } from './directives/tracking.module';
import { NormalizeImagePipe } from './pipes/normalize-image.pipe';
import { StepPageResolver } from './resolvers/step-page.resolver';
import { SecurityService } from './services/security.service';
import { LazyDomModule } from './utils/lazy-dom.module';
import { LazyImageModule } from './utils/lazy-image.module';
import { StorageModule } from './utils/storage.module';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { DssDialogModule } from '@dss/components/dialog';

const components = [
  NormalizeImagePipe,
  ImageCentereWithCardsComponent,
  ExpandableListComponent,
  GalleryComponent,
  IconCardsComponent,
  ModalComponent,
  ModalCPFComponent,
  OfferItemComponent,
  SectionHeaderComponent,
  SectionTextComponent,
  TitleWithItemsComponent,
  InputCpfComponent,
  ErrorDialogComponent
];

const modules = [
  BannerModule,
  HeaderModule,
  StorageModule,
  PageModule,
  ButtonModule,
  TrackingModule,
  FloatingButtonModule,
  ListModule,
  SwiperModule,
  IMaskModule,
  LazyDomModule,
  LazyImageModule,
  CookiesAgreementModule
];

const dssModules = [
  DssTypographyModule,
  DssButtonModule,
  DssFormFieldModule,
  DssHeaderModule,
  DssScrollbarModule,
  DssAccordionModule,
  DssDrawerModule,
  ButtonModule,
  DssBadgeModule,
  DssCarouselModule,
  DssCardModule,
  DssDialogModule
]

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...modules,
    ...dssModules
  ],
  exports:[
    ...modules,
    ...components,
    ...dssModules
  ],
  providers: [
    StepPageResolver,
    SecurityService,
    StorageModule
  ]
})
export class SharedModule { }
