<div class="margin-bottom">
  <form [formGroup]="form">
    <dss-form-field [isError]="(cpf?.touched || cpf?.dirty) && !cpf?.valid">
      <input
        trackingFill
        [eventLabel]="'input:cpf'"
        id="inputCPF"
        formControlName="cpf"
        inputmode="numeric"
        class="input-cpf"
        [imask]="cpfMask"
        [unmask]="false"
        #inputcpf
      />
      <label dssLabel>{{ cpfCMSInput?.label }}</label>
      <span class="messager_erro" *ngIf="cpf?.errors?.required" dssError>
        {{ cpfCMSInput?.validator_required }}
      </span>
      <span
        class="messager_erro"
        *ngIf="cpf?.value.length == 14 && cpf?.errors?.invalidCPF"
        dssError
        >{{ cpfCMSInput?.validator_incorrectValue }}
      </span>
    </dss-form-field>
  </form>
</div>
