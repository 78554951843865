import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'mob-offer-item',
    templateUrl: './offer-item.component.html',
    styleUrls: ['./offer-item.component.scss']
})
export class OfferItemComponent implements OnInit {

    @Input() icone: any;
    @Input() alt: any;
    @Input() offer_title: any;
    @Input() offer_description: any;

    constructor() { }

    ngOnInit() {
    }

}
