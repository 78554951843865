import packageInfo from '../config/active.env.json';

const ENVIRONMENT = 'PRO';

export const environment = {
   production: true,
     defaultEnv: ENVIRONMENT,
     apiTimeout:packageInfo.apiTimeout,
     appKey:packageInfo.appKey,
      cmsXStaging:packageInfo.cmsXStaging,
     cmsEndpoint:packageInfo.cmsEndpoint,
     cmsResourcePath:packageInfo.cmsResourcePath,
     cpfEndpoint:packageInfo.cpfEndpoint,
     cpfResourcePath:packageInfo.cpfResourcePath,
     mob:packageInfo.mob,
     opf:packageInfo.opf,
     fopa:packageInfo.fopa,
     isStaging:packageInfo.isStaging,
     keyPEM:packageInfo.keyPEM,
     flag_hotjar: true,
     flag_google_analytics: true,
     key_google_analytics: '',
     id_google_tag_manager: 'GTM-THV465P',
     encryptionPEM: packageInfo.encryptionPEM,
     flag_flickering: packageInfo.flagFlickering,
     key_adobe_target: packageInfo.adobeTargetKey
};
