import { Component, Input } from "@angular/core";

@Component({
    selector: 'mob-section-text',
    templateUrl: './section-text.component.html',
    styleUrls: ['./section-text.component.scss']
  })
export class SectionTextComponent {
  @Input() text:string;
  @Input() backgroundColor:string='#fff';
  @Input() defaultAlignment:string='flex-start';
  @Input() fontSize:string;//'small'|'medium'|'large'='medium';
  @Input() fontColor:string='gray';

  public inputYear;

  constructor() {
    this.inputYear = (new Date()).getFullYear();
  }
  ngOnInit(): void {
    this.text = this.text.replace('{{value}}', this.inputYear)
  }
}
